import { storyblokEditable } from '@storyblok/react'
import React from 'react'
import styled from 'styled-components'

import { ContentThumb } from 'components/blocks/sections/directories/thumbs/ContentThumb'
import { Carousel } from 'components/ui/deprecated/Carousel'
import { Container } from 'components/ui/deprecated/Layout'
import { SelectedPatientStoriesStoryblok } from 'lib/storyblok/types'

type Props = {
  block: SelectedPatientStoriesStoryblok
}

export const SelectedPatientStories = ({
  block,
  ...rest
}: Props): JSX.Element => {
  const { title, items, variant } = block

  return (
    <section {...storyblokEditable(block)}>
      <h3 className="pt-10 lg:pt-15 font-regular text-title-medium md:text-title mx-auto max-w-screen-desktop px-5 md:px-20">
        {title}
      </h3>

      {variant === 'grid' && (
        <Grid {...rest}>
          {items?.map(
            (item) =>
              typeof item !== 'string' && (
                <ContentThumb key={item.uuid} item={item} />
              )
          )}
        </Grid>
      )}

      {variant === 'slider' && (
        <Carousel itemsPerView={3} {...rest}>
          {items?.map(
            (item) =>
              typeof item !== 'string' && (
                <ContentThumb key={item.uuid} item={item} />
              )
          )}
        </Carousel>
      )}
    </section>
  )
}

const Grid = styled(Container)`
  padding-top: 2rem;
  padding-bottom: 2rem;

  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 2rem;

  ${({ theme }) => theme.media.md} {
    grid-template-columns: repeat(2, 1fr);

    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
  }

  ${({ theme }) => theme.media.lg} {
    grid-template-columns: repeat(3, 1fr);
  }
`
